<template>
    <div id="content">
        <DataTable :value="data" class="p-datatable-responsive"  selectionMode="single" dataKey="id" :paginator="true" :rows="filters.perPage" :filters="filters"
                   :totalRecords="totalRows" :loading="loading" @page="onPage($event)" :lazy="true"
                   :scrollable="true"
                   scrollHeight="calc(100vh - 310px)"
                   :stickyHeader="true"
                   id="table"
        >
            <template #empty>
                <h6 class="text-center mt-2" v-if="!loading">No se encontraron registros que coincidan con la búsqueda</h6>
                <h6 class="text-center mt-2"  v-else>Buscando</h6>
            </template>
            <template #header>
                <div class="d-flex justify-content-between">
                    <h5 class="text-left">Gestionar Trabajadores</h5>
                    <Button title="Buscar" icon="pi pi-search" @click="reload()" class="mx-2"/>
                </div>


            </template>
            <Column field="user" header="Usuario" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Usuario</span>
                    {{slotProps.data.id}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.documento" class="p-column-filter" placeholder="Por usuario" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="type" header="Nombres" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Nombres</span>
                    {{slotProps.data.NOMBRES}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.NOMBRES" class="p-column-filter" placeholder="Buscar por nombre @keyup.enter.native="  />

                </template>
            </Column>
            <Column field="amaterno" header="A. Materno" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">A. Materno</span>
                    {{slotProps.data.amaterno}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.amaterno" class="p-column-filter" placeholder="Buscar por apellido materno" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="apaterno" header="A. Paterno" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">A. Paterno</span>
                    {{slotProps.data.apaterno}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.apaterno" class="p-column-filter" placeholder="Buscar por A. Paterno" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="CELULAR" header="Celular" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Celular</span>
                    {{slotProps.data.CELULAR}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.CELULAR" class="p-column-filter" placeholder="Buscar por A. Paterno" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="correo" header="Correo" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Correo</span>
                    {{slotProps.data.correo}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.correo" class="p-column-filter" placeholder="Buscar por correo" @keyup.enter.native="reload()"/>
                </template>
            </Column>
            <Column field="SUPERVISOR" header="Cargo" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">SUPERVISOR</span>
                    {{slotProps.data.SUPERVISOR ? 'Supervisor de Campo':'Trabajador de Campo'}}
                </template>
                <template #filter>
                    <b-form-select  v-model="filters.SUPERVISOR" :options="supervisors"
                                    value-field="id"
                                    text-field="text"
                                    @change="reload"
                    ></b-form-select>
                </template>
            </Column>
            <Column field="Estado" header="Estado" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Estado</span>
                    {{slotProps.data.Retirado == '1' ? 'Inactivo':'Activo'}}
                </template>
                <template #filter>
                    <b-form-select  v-model="filters.Retirado" :options="outs"
                                    value-field="id"
                                    text-field="text"
                                    @change="reload"
                    ></b-form-select>
                </template>
            </Column>
            <Column field="last_downloaded" header="Ultima descarga" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Ultima descarga</span>
                    <span v-if="slotProps.data.last_downloaded">
                        {{slotProps.data.last_downloaded | moment("DD/MM/YYYY hh:mm a")}}
                    </span>

                </template>
            </Column>
            <Column field="action" header="Acciones" :sortable="false">
                <template #body="slotProps" >
                    <div class="d-flex justify-content-around align-content-center">
                        <Button   icon="pi pi-file"  class="m-1" title="Ver boletas de pago" @click="changeRoute(slotProps.data)"/>
                        <Button  icon="pi pi-pencil"  class="m-1"  title="Editar" @click="onEdit(slotProps.data)"/>
                        <Button  icon="pi pi-comment"  class="m-1"
                                :title="!slotProps.data.can_consult ? 'Permitir consulta':'No permitir consulta'"
                                @click="onShowConsulted(slotProps.data)"
                                :disabled="slotProps.data.Retirado!='0'"
                                v-bind:class="{'p-button-secondary': slotProps.data.can_consult }"/>
                        <Button  icon="pi pi-key"  class="m-1"  title="Reiniciar clave" @click="onResetPassword(slotProps.data)"/>
                    </div>

                </template>
                <template #filter>
                    <button class="btn btn-company btn-sm" @click="clearFilters()">limpiar</button>
                </template>

            </Column>
        </DataTable>

        <Dialog header="Pregunta" :visible.sync="question"   :modal="true">
            ¿Desea reiniciar la clave?
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="question = false" class="p-button-secondary"/>
                <Button label="Si" icon="pi pi-check" @click="passwordChange()"/>
            </template>
        </Dialog>
        <Dialog header="Pregunta" :visible.sync="showConsulted"   :modal="true">
            ¿Desea {{selected && !selected.can_consult  ? 'permitir':'no permitir'}} consultas ?
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="showConsulted = false" class="p-button-secondary"/>
                <Button label="Si" icon="pi pi-check" @click="onToggleConsultation()"/>
            </template>
        </Dialog>
        <Dialog :visible.sync="showForm" :style="{width: '800px'}" header="Editar" :modal="true">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)" ref="form" name="form">

                    <div class="p-cardialog-content" >
                        <b-container  style="min-height:50vh" class="mt-3">
                            <b-row class="mb-3">
                                <b-col >
                                    <div class="form-group ">
                                        <ValidationProvider name="correo" :rules="{ email: true}"
                                                            v-slot="validationContext" >
                                            <div class="p-float-label">
                                                <InputText id="title" type="text" v-model="model.correo"  class="w-100"/>
                                                <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">
                                                    {{ validationContext.errors.join(',')}}</b-form-invalid-feedback>
                                                <label for="title">Correo</label>

                                            </div>

                                        </ValidationProvider>
                                    </div>
                                </b-col>
                                <b-col >
                                    <div class="form-group ">
                                        <ValidationProvider name="telefono" :rules="{ numeric: true, required: true}"
                                                            v-slot="validationContext" >
                                            <div class="p-float-label">
                                                <InputText id="CELULAR" type="tel" v-model="model.CELULAR"  class="w-100" maxLength="9" minLength="9"/>
                                                <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">
                                                    {{ validationContext.errors.join(',')}}</b-form-invalid-feedback>
                                                <label for="title">Celular</label>

                                            </div>

                                        </ValidationProvider>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mb-3">
                                <b-col  lg="4">
                                    <div class="form-group mt-3 ">
                                        <label for="title">DNI</label>
                                        <InputText   type="text" v-model="model.documento"  class="form-control" readonly="true" disabled=""/>

                                    </div>
                                </b-col>
                                <b-col  lg="8">
                                    <div class="form-group mt-3 ">
                                        <label for="title">Nombres</label>
                                        <InputText   type="text" v-model="model.NOMBRES"  class="form-control" readonly="true" disabled=""/>

                                    </div>
                                </b-col>
                                <b-col  lg="6">
                                    <div class="form-group mt-3 ">
                                        <label for="title">Apellido paterno</label>
                                        <InputText   type="text" v-model="model.apaterno"  class="w-100 form-control" readonly="true" disabled=""/>

                                    </div>
                                </b-col>
                                <b-col  lg="6">
                                    <div class="form-group mt-3  ">
                                        <label for="title">Apellido materno </label>
                                        <InputText   type="text" v-model="model.amaterno"  class="w-100 form-control" readonly="true" disabled=""/>

                                    </div>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col  lg="4">
                                    <div class="form-group mt-3 ">
                                        <label for="title">Usuario</label>
                                        <InputText   type="text" v-model="model.codigo"  class="w-100 form-control" readonly="true" disabled=""/>
                                    </div>
                                </b-col>
                                <b-col  lg="4">
                                    <div class="form-group mt-3">
                                        <label for="title">Supervisor</label>

                                        <span      class="form-control" readonly="true" disabled="">
                                                {{model.SUPERVISOR ==  'S' ? 'Si':'No'}}
                                            </span>
                                    </div>
                                </b-col>
                                <b-col  lg="4">
                                    <div class="form-group mt-3 ">
                                        <label for="title">Estado</label>

                                        <span      class="w-100 form-control" readonly="true" disabled="">
                                                {{model.Retirado ==  '1' ? 'Inactivo':'Activo'}}
                                            </span>
                                    </div>
                                </b-col>

                            </b-row>
                        </b-container>
                    </div>


                </b-form>
            </validation-observer>
            <template #footer>

                <Button :label="!loading ? 'Guardar':'Guardando'" :icon="loading ? 'pi pi-spin pi-spinner' :'pi pi-check'"  class="p-button-success" @click="onSubmit()" :disabled="loading"   />
            </template>
        </Dialog>

    </div>


</template>

<script>

    import Crud from '../../service/Crud';

    import Dialog from 'primevue/dialog';
    import removeNullable from "../../tools/removeNullable";
    import { BFormInvalidFeedback } from 'bootstrap-vue';



    export default {

        components: {
            Dialog, BFormInvalidFeedback
        },
        data() {
            return {
                filters: {
                    page:1,
                    perPage: 20,
                },
                totalRows: 0,
                loading:false,
                question:false,
                showForm:false,
                showConsulted:false,
                model:{},
                data: [],
                layout: 'list',
                sortKey: null,
                sortOrder: null,
                sortField: null,
                crud: null,
                selected:null,
                supervisors:[
                    {
                        text:'Todos'
                    },
                    {
                        id:'S',
                        text:'Supervisor de Campo'
                    },
                    {
                        id:'N',
                        text:'Trabajador de Campo'
                    }
                ],
                outs:[
                    {
                        text:'Todos'
                    },
                    {
                        id:'1',
                        text:'Inactivos'
                    },
                    {
                        id:'0',
                        text:'Activos'
                    }
                ],
            }

        },
        created() {
            this.$moment.locale('es');

            this.crud = Crud('personals');
            this.reload();
        },


        methods: {
            onResetPassword(item){
                this.selected = item;
                this.question = true;
            },
            onEdit(item){
                this.selected = item;
                this.model = Object.assign({}, item);
                this.showForm = true;
            },
            onShowConsulted(item){
                this.selected = item;
                this.model = Object.assign({}, item);
                this.showConsulted = true;
            },
            onSubmit(){

                this.$refs.observer.validate();
                if(!this.$refs.observer.flags.invalid){
                    this.sendModelForm(this.model.id);

                }
            },
            onToggleConsultation(){
                this.crud.patch(this.selected.id,'toggle-consult').then(()=>{
                    this.$toast.add({severity:'success', summary:"Guardado", life: 3000});
                    this.reload();
                    this.showConsulted = false;
                }, ()=> {this.loading = false; });
            },
            sendModelForm(id = null){
                this.loading = true;
                let send = Object.assign({}, this.model)
                send = removeNullable(send);

                this.crud.update(id,send).then( () => {
                    this.onSuccess();
                },  (response) => {
                    this.onError(response)
                });

            },
            onSuccess(){
                this.$toast.add({severity:'success', summary:"Guardado", life: 3000});
                this.loading = false;
                this.showForm = false;
                this.reload();

            },
            onError(response){
                this.$toast.add({severity:'error', summary: response.data.message, life: 3000});
                this.loading = false;
            },
            reload(){
                this.loading = true;
                this.crud.findAll(this.filters).then(res =>{
                    this.data = res.data ;
                    this.filters.totalRows = res.meta.total;
                    this.loading = false;

                });
            },
            onPage(event){
                this.filters.page  = event.page + 1;
                this.reload();
            },
            passwordChange() {
                // console.log(this.selected.id);
                this.loading = true;
                this.crud.patch(this.selected.id, 'password-reset').then(() => { this.reload();this.question = false; });
            },
            changeRoute(item){
                this.$router.push({ name: 'payment-tickers', params: { userId: item.id } })

            },
            clearFilters() {
                const omit  = ['perPage', 'totalRows', 'page'];
                Object.keys(this.filters).map(((value) => { if( omit.indexOf(value) === -1){ this.filters[value] = null} }));
                this.reload();
            }
        }
    }
</script>




<style lang="scss" scoped>



    .p-column-filter {
        margin-top: .5em;
    }

    /deep/ .p-dropdown {
        width: 12em;
    }

    /deep/ .p-dataview {
        .car-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2em;

            & > div {
                display: flex;
                align-items: center;

                img {
                    margin-right: 14px;
                }
            }
        }

        .car-detail {
            padding: 0 1em 1em 1em;
            margin: 1em;
        }
    }

    .p-carousel {
        .p-carousel-content {
            .p-carousel-item {
                .car-details {
                    > .p-grid {
                        border: 1px solid #b3c2ca;
                        border-radius: 3px;
                        margin: .3em;
                        text-align: center;
                        padding: 2em 0 2.25em 0;
                    }
                }

                .car-data {
                    .car-title {
                        font-weight: 700;
                        font-size: 20px;
                        margin-top: 24px;
                    }

                    .car-subtitle {
                        margin: .25em 0 2em 0;
                    }

                    button {
                        margin-left: .5em;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .p-dataview {
            .car-details {
                img {
                    width: 75px;
                }
            }
        }
    }

    .p-dropdown-car-option {
        img {
            vertical-align: middle;
            margin-right: .5em;
            width: 24px;
        }

        span {
            float: right;
            margin-top: .125em;
        }
    }

</style>
